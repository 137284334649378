export class adressStructure{



  constructor(
   public county?: string,
   public city?: string,
   public street?: string
  ){}




}
