export class KetelStructure{
  constructor(
    public name: string,
    public sn?: string,
    public kWt?: number
  ) {}



}
