import { adressStructure } from './adress.models';
import { ContractStructure } from './contract.models';
import { GtStructure } from './gt.models';
import { KetelStructure } from './ketel.models';
import { MyTel } from './tel.models';

export class OrderStructure {
  id: number;
  number: number;

  date_accepted: Date;
  date_issued?: Date;
  date_closed?: Date;
  date_tel?: Date;
  date_tel2?: Date;

  adress?: adressStructure = new adressStructure('', '', '');
  tel?: MyTel;
  tel2?: MyTel;

  state: stateOrder;
  resason?: string;
  conclusion?: string; // заключення, коментар майстра, оператора.
  price?: number;
  ketel?: KetelStructure = new KetelStructure('', '');
  user?: string;
  master?: string;
  typeOrde: typeOrder; //Тип замовлення (Гарантійний, сервісний, т.д.)
  rating?: number; //Рейтинг замовлення. Зворотній звязок клієнта.
  review?: string; //Відгук клієнта
  debtFlag: boolean; //борг
  debt?: number;
  important?: boolean; //важливість
  contract?: ContractStructure[] = [];
  gt?: GtStructure[] = [];
  version: number; // версія документу
}

export enum stateOrder {
  accepted = 'Прийнята',
  issued = 'Видана',
  issuedIndependently = 'Видана самостійно',
  canceled = 'Відмінена',
  done = 'Виконана',
  waiting = 'Очікує підтвердження',
}

export enum typeOrder {
  gr = 'Гарантійний',
  sr = 'Сервісний',
  dg = 'Договір',
}
